import styles from '../../style.module.scss';
import Link from 'next/link';
import { Box, Typography, CardMedia, Card, Grid } from '@mui/material';
import { Steps } from '../Steps';
import { useTranslation } from 'next-i18next';

export const Banner = () => {
  const { t } = useTranslation();

  return (
    <Box className={styles.banner}>
      <Box className={styles.banner_data}>
        <Grid container className={styles.banner_data_description} rowGap={1} mt={1}>
          <Typography sx={{ fontFamily: 'Playfair', fontSize: '16px' }}>
            {t('banner.description_1')}.
          </Typography>
          <Typography sx={{ fontFamily: 'Playfair', fontSize: '16px' }}>
            {t('banner.description_2')}
          </Typography>
          <Typography sx={{ fontFamily: 'Playfair', fontSize: '16px' }}>
            {t('banner.description_3')}{' '}
            <Link href="/faq" passHref style={{ fontFamily: 'Playfair', fontWeight: '800' }}>
              {t('banner.link')}
            </Link>
            !
          </Typography>
        </Grid>
        <Box className={styles.banner_data_media}>
          <Typography
            sx={{
              color: '#FBBE68',
              fontSize: '18px',
              fontFamily: 'Playfair',
              fontWeight: 800,
            }}
          >
            {t('banner.video_title')}:
          </Typography>
          <Card
            sx={{
              maxWidth: 800,
              marginTop: '15px',
            }}
          >
            <CardMedia
              className={styles.banner_data_media_iframe}
              component="iframe"
              src="https://www.youtube.com/embed/NmT8Upaajgg"
              title="YouTube video"
              allow="autoplay"
            />
          </Card>
        </Box>
      </Box>
      <Steps />
    </Box>
  );
};
