import logo1 from '@app/assets/images/247@1x.png';
import logo3 from '@app/assets/images/iib@1x.png';
import logo2 from '@app/assets/images/sca_logo_new.png';
import { WelcomeBlock } from '@app/components/WelcomeBlock';
import styles from '@app/styles/Home.module.scss';
import { Box, Grid, Link, Typography } from '@mui/material';
import Image from 'next/image';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';

const Home = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ marginTop: '20px' }} className={styles.container}>
      <WelcomeBlock />
      <Grid container mt={4} columnSpacing={2} justifyContent="space-around">
        <Grid
          item
          xs={12}
          lg={5}
          sx={{
            fontWeight: 800,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#FBBE68',
            marginBottom: '20px',
          }}
          className={styles.mb}
        >
          {t('home.welcome_text')}
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            fontWeight: 800,
            fontSize: '18px',
            lineHeight: '17px',
            color: '#fff',
            marginBottom: '20px',
          }}
        >
          <Typography sx={{ marginBottom: '20px', fontWeight: 800 }} className="font-days-one">
            {t('home.description_1')}
          </Typography>
          <Typography sx={{ marginBottom: '20px' }}>{t('home.description_2')}</Typography>
          <Typography sx={{ marginBottom: '20px' }}>{t('home.description_3')}</Typography>
          <Typography>{t('home.description_4')}</Typography>
        </Grid>
      </Grid>

      <Grid
        mt={1}
        mb={1}
        container
        columnSpacing={8}
        justifyContent="space-around"
        gap={8}
        rowSpacing={1}
        width="100%"
        ml="auto"
      >
        <Box component={Grid} container item xs={8} lg={2} justifyContent="center">
          <Link href="https://247and.co/">
            <Image src={logo1} alt="Logo 1" />
          </Link>
        </Box>
        <Box component={Grid} container item xs={8} lg={2} justifyContent="center">
          <Link href="https://sca-innovative.com/">
            <Image src={logo2} height={100} width={100} alt="Logo 2" />
          </Link>
        </Box>
        <Box component={Grid} container item xs={8} lg={2} justifyContent="center">
          <Link href="https://iib-insurance.co.uk">
            <Image src={logo3} alt="Logo 3" />
          </Link>
        </Box>
      </Grid>
    </Box>
  );
};

export const getStaticProps = async (context: any) => {
  const { locale } = context;
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['common', 'packages'])),
    },
  };
};

export default Home;
