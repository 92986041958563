import axios, { AxiosError, ResponseType } from 'axios'
import { toast } from 'react-toastify'

const requestFile = async (
  url: string,
  type: ResponseType | undefined = 'blob',
  isBase64 = false,
) => {
  try {
    const response = await axios.post(
      url,
      {
        base64: isBase64,
      },
      {
        responseType: type,
        headers: {
          Authorization: `Bearer ${global.localStorage.getItem('token')}`,
        },
      },
    )

    return response.data
  } catch (error) {
    const axiosError = error as AxiosError
    const errMessage = axiosError.message || 'Download file error'
    toast.error(errMessage)
    console.log('Download file error:', error)
  }
}

type FileProps = {
  fileUrl: string
  fileName: string
}

export const downloadFile = async ({ fileUrl, fileName }: FileProps) => {
  const fileData = await requestFile(fileUrl)
  const blob = new Blob([fileData])
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = fileName

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

export const downloadFileAsBase64 = async (fileUrl: string): Promise<string> => {
  const fileData: string | undefined = await requestFile(fileUrl, 'text', true)
  if (fileData?.includes('base64')) {
    return fileData
  } else {
    return `data:image/png;base64,${fileData}`
  }
}
