import { useMemo } from 'react';
import { useAccount } from 'wagmi';
import { useUser } from '@app/hooks/useUser';
import { useModal, useProfile } from '@app/hooks';
import Image from 'next/image';
import { downloadFile, downloadFileAsBase64 } from '@app/utils/files';
import { QRShareBlock } from '@app/components/WelcomeBlock/components/QRShareBlock';
import { PackagesList } from '../packageComponents/PackagesList';
import { isBrowserMetaMask } from '@app/utils/user';
import InsurancePolicyLeft from '@app/assets/icons/insurancePolicyLeft.svg';
import InsurancePolicyRight from '@app/assets/icons/insurancePolicyRight.svg';
import { Box, Link, Typography } from '@mui/material';
import OldGrid from '@mui/material/Grid';
import Grid from '@mui/material/Unstable_Grid2';
import { Banner } from './components/Banner';
import styles from './style.module.scss';
import { ECurrentModal } from '@app/types';
import { useTranslation } from 'next-i18next';

export const WelcomeBlock = () => {
  const { address, isConnected } = useAccount();
  const { data: profile, isLoggedIn } = useProfile();
  const { t } = useTranslation();
  const { user } = useUser();
  // EM-292 const isKycConfirmed = profile?.reviewStatus === 'completed'

  const { setImageDataProps, setCurrentModal } = useModal();

  const getSertificate = () => {
    const endpoint = `${process.env.REACT_APP_HTTP_API_URL}/user/insurance-certificate?address=${address}`;
    if (isBrowserMetaMask()) {
      downloadFileAsBase64(endpoint).then((base64) => {
        if (base64) {
          setImageDataProps({ image: base64 });
          setCurrentModal(ECurrentModal.IMAGE_PREVIEW);
        }
      });
    } else {
      downloadFile({
        fileName: 'Insurance Certificate.pdf',
        fileUrl: endpoint,
      });
    }
  };

  const isShowQRShareBlock: boolean = useMemo(() => {
    return !isLoggedIn || !profile?.partnerAgreementAcceptedAt;
  }, [isLoggedIn, profile?.partnerAgreementAcceptedAt]);

  const hasPackage = user?.packageId && user?.packageId > BigInt(0);
  return (
    <Box>
      <OldGrid container spacing={2}>
        <OldGrid item xs={12} xl={8}>
          <Typography className={styles.title} variant={'h4'}>
            {t('welcome_block.welcome_to_sca_alliance')}
          </Typography>
          <Typography variant="h4" className={styles.subTitle}>
            {t('welcome_block.sub_title')}
          </Typography>
          {/* <Box className={styles.warning}>
          <Image src={Warning} />
          <Typography className={styles.warning_text}>
            {t('welcome_block.important_notice')}
          </Typography>
        </Box> */}
        </OldGrid>
        <OldGrid item xs={12} xl={4}>
          {isShowQRShareBlock && <QRShareBlock />}
        </OldGrid>
      </OldGrid>

      <Banner />

      {isConnected && !hasPackage && (
        <OldGrid container className={styles.packages} justifyContent="space-between">
          <PackagesList limit={3} />

          {/* <div className={styles.morePackages}>
            <span className={styles.morePackagesName}>All packages</span>
            <h3 className={styles.morePackagesTitle}>
              More <br className={styles.br} />
              tokens...
            </h3>
            <Button
              type={EButtonType.OUTLINED}
              href="/packages"
              customClass={styles.moreButton}
            >
              View more
            </Button>
          </div> */}
        </OldGrid>
      )}

      {/*TODO*/}
      {/*{isLoggedIn && hasPackage && isKycConfirmed && (*/}
      {isLoggedIn && hasPackage && (
        <Link
          className={styles.downloadInsuranceBanner}
          onClick={getSertificate}
          sx={{ textDecoration: 'none' }}
        >
          <Grid
            container
            className={styles.insurancePolicy}
            alignItems="center"
            spacing={4}
            m={0}
            justifyContent="space-between"
          >
            <Grid xs={12} lg={9}>
              <Grid
                container
                mb={2}
                wrap="nowrap"
                alignItems={{ xs: 'center', xl: 'start' }}
                gap="10px"
              >
                <Grid xs="auto">
                  <Image src={InsurancePolicyLeft} alt="" />
                </Grid>

                <Typography variant={'h4'} className={styles.insurancePolicy_text}>
                  {t('welcome_block.click_download')}
                </Typography>
              </Grid>
              <Typography
                variant="caption"
                sx={{
                  color: '#000',
                  lineHeight: 1.2,
                  display: 'block',
                }}
                mt={1}
              >
                {t('welcome_block.description')}
              </Typography>
            </Grid>
            <Grid lg={3} xs={12} textAlign={{ xs: 'center', lg: 'right' }}>
              <Image src={InsurancePolicyRight} className={styles.insurancePolicy__right} alt="" />
            </Grid>
          </Grid>
        </Link>
      )}
    </Box>
  );
};
