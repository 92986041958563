import styles from './style.module.scss';
import classNames from 'classnames';
import { Box, Typography } from '@mui/material';
import { Button } from '@app/components/shared/Button';
import { EButtonType, ECurrentModal } from '@app/types';
import { useModal } from '@app/hooks';
import React from 'react';
import { useTranslation } from 'next-i18next';

const ModalHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography className={styles.title}>{t('qr_share_block.title')}</Typography>
      <Typography className={styles.subtitle}>
        {t('qr_share_block.sub_title_1')} <a href={location.origin}>{location.hostname}</a>{' '}
        {t('qr_share_block.sub_title_2')}
      </Typography>
    </>
  );
};

export const QRShareBlock = () => {
  const { setCurrentModal, setReferralLinkProps } = useModal();
  const { t } = useTranslation();

  return (
    <Box className={classNames(styles.wrapper)}>
      <Typography className={styles.text}>{t('qr_share_block.paper_title')}</Typography>
      <Button
        customClass={styles.btn}
        type={EButtonType.FILLED}
        onClick={() => {
          setReferralLinkProps({ header: <ModalHeader /> });
          setCurrentModal(ECurrentModal.QRCode);
        }}
      >
        {t('qr_share_block.description_qr')}
      </Button>
    </Box>
  );
};
