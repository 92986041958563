import React, { useCallback, useState } from 'react';
import { useProfile } from '@app/hooks';
import Link from 'next/link';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';
import Question from '@app/assets/icons/question.png';
import { CustomDialog } from '@app/components/shared/Dialog';
import styles from '../../style.module.scss';
import { useTranslation } from 'next-i18next';

export const Steps = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useProfile();
  const [showUnAuthStakingDialog, setShowUnAuthStakingDialog] = useState(false);
  // const isKycConfirmed = profile?.reviewStatus === 'completed'

  const handleShowDialog = useCallback(() => {
    setShowUnAuthStakingDialog(true);
  }, []);

  return (
    <Box className={styles.bannerSteps}>
      <Box className={styles.bannerStep}>
        <svg
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.bannerIconWrapper}
        >
          <path
            d="M18 15V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0H16C17.1 0 18 0.9 18 2V3H9C7.89 3 7 3.9 7 5V13C7 14.1 7.89 15 9 15H18ZM9 13H19V5H9V13ZM13 10.5C12.17 10.5 11.5 9.83 11.5 9C11.5 8.17 12.17 7.5 13 7.5C13.83 7.5 14.5 8.17 14.5 9C14.5 9.83 13.83 10.5 13 10.5Z"
            fill="url(#paint0_linear_880_417)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_880_417"
              x1="2.36164e-06"
              y1="18"
              x2="-2.79251"
              y2="4.51178"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4DABF5" />
              <stop offset="1" stopColor="#5FEDCF" />
            </linearGradient>
          </defs>
        </svg>
        <Link href="https://metamask.io/download/" className={styles.bannerStepTitle} passHref>
          {t('steps.create_wallet')}
        </Link>
      </Box>

      <Box className={styles.bannerStep}>
        <svg
          viewBox="0 0 16 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.bannerIconWrapper}
        >
          <path
            d="M10 18L2 18C0.900001 18 7.47465e-07 17.1 6.99382e-07 16L8.74228e-08 2C3.93402e-08 0.9 0.9 -3.93402e-08 2 -8.74228e-08L10 -4.37114e-07C11.1 -4.85196e-07 12 0.899999 12 2L12 16C12 17.1 11.1 18 10 18ZM10 16L10 2L2 2L2 16L10 16ZM3 22L14 22C15.1 22 16 21.1 16 20L16 3C15 3 15.1 3 14 3L14 20L3 20C3 21.1 3 21 3 22Z"
            fill="url(#paint0_linear_880_441)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_880_441"
              x1="16"
              y1="2.03515e-06"
              x2="3.80463"
              y2="-1.93827"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4DABF5" />
              <stop offset="1" stopColor="#5FEDCF" />
            </linearGradient>
          </defs>
        </svg>
        <Link href="/packages" className={styles.bannerStepTitle}>
          {t('steps.buy_package')}
        </Link>
      </Box>

      <Box
        className={styles.bannerStep}
        onClick={() => {
          // TODO
          // if (isLoggedIn && profile?.user && isKycConfirmed) return
          if (isLoggedIn) return;
          handleShowDialog();
        }}
      >
        <svg
          viewBox="0 0 22 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.bannerIconWrapper}
        >
          <path
            d="M14 0C9.58 0 6 3.58 6 8C6 12.42 9.58 16 14 16C18.42 16 22 12.42 22 8C22 3.58 18.42 0 14 0ZM14 14C10.69 14 8 11.31 8 8C8 4.69 10.69 2 14 2C17.31 2 20 4.69 20 8C20 11.31 17.31 14 14 14ZM2 8C2 5.39 3.67 3.17 6 2.35V0.26C2.55 1.15 0 4.27 0 8C0 11.73 2.55 14.85 6 15.74V13.65C3.67 12.83 2 10.61 2 8Z"
            fill="url(#paint0_linear_880_444)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_880_444"
              x1="2.73453e-06"
              y1="16"
              x2="-1.93827"
              y2="3.80463"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4DABF5" />
              <stop offset="1" stopColor="#5FEDCF" />
            </linearGradient>
          </defs>
        </svg>
        <Link
          className={styles.bannerStepTitle}
          // TODO
          // href={isLoggedIn && profile?.user && isKycConfirmed ? '/funds-analysis' : '/'}
          href={isLoggedIn ? '/funds-analysis' : '/'}
        >
          {t('steps.start_staking')}
        </Link>
      </Box>

      <Box className={styles.bannerStep}>
        <Box className={styles.bannerIconWrapper}>
          <Image src={Question} style={{ width: 30, height: 30 }} alt="" />
        </Box>

        <Link href="/faq" className={styles.bannerStepTitle}>
          {t('steps.faq')}
        </Link>
      </Box>

      <CustomDialog
        open={showUnAuthStakingDialog}
        title={t('steps.modal.title')}
        handleClose={() => setShowUnAuthStakingDialog(false)}
      >
        <Typography>{t('steps.modal.dear_client')},</Typography>
        <Typography>{t('steps.modal.description')}</Typography>
      </CustomDialog>
    </Box>
  );
};
